<template>
    <div class="card card-default" :class="loading ? 'whirl' : ''">
        <div class="card-header bg-gray-lighter text-bold">{{$t('common.view.settings.two_factor_auth.heading')}}</div>
        <div class="card-body" v-if="!secret">

            <img src="img/2fa/google_authenticator_icon.png" class="float-left wd-sm mr-3 border-right pr-3" alt="google authenticator logo"/>

            {{$t('common.view.settings.two_factor_auth.description')}}


            <div class="my-3">
                <div class="d-inline-block  mr-3" :class="{'text-primary': is2faEnabled(), 'text-secondary': !is2faEnabled()}">
                    <i class="fa" :class="{'fa-check': is2faEnabled(), 'fa-times': !is2faEnabled()}"></i> {{$t('common.view.settings.two_factor_auth.secure.login')}}
                </div>
                <div class="d-inline-block mr-3" :class="{'text-primary': is2faEnabled(), 'text-secondary': !is2faEnabled()}">
                    <i class="fa" :class="{'fa-check': is2faEnabled(), 'fa-times': !is2faEnabled()}"></i> {{$t('common.view.settings.two_factor_auth.secure.withdraw')}}
                </div>
                <div class="d-inline-block mr-3" :class="{'text-primary': is2faEnabled(), 'text-secondary': !is2faEnabled()}">
                    <i class="fa" :class="{'fa-check': is2faEnabled(), 'fa-times': !is2faEnabled()}"></i> {{$t('common.view.settings.two_factor_auth.secure.payment')}}
                </div>
                <div class="d-inline-block mr-3" :class="{'text-primary': is2faEnabled(), 'text-secondary': !is2faEnabled()}">
                    <i class="fa" :class="{'fa-check': is2faEnabled(), 'fa-times': !is2faEnabled()}"></i> {{$t('common.view.settings.two_factor_auth.secure.mail_change')}}
                </div>
            </div>

            <hr/>

            <b-btn-toolbar class="justify-content-end">
                <b-btn v-if="!is2faEnabled()" variant="primary" @click="generateSecret()">{{$t('common.view.settings.two_factor_auth.enable')}}</b-btn>
                <b-btn v-if="is2faEnabled()" variant="warning" @click="disable()">{{$t('common.view.settings.two_factor_auth.disable')}}</b-btn>
            </b-btn-toolbar>


        </div>
        <div class="card-body" v-if="secret">

            <form-wizard class="vue-form-wizard-vertical">
                <!-- By using a custom header markup we can pass html in title attribute -->
                <template slot="step" slot-scope="props">
                    <wizard-step :tab="props.tab" @click.native="props.navigateToTab(props.index)" @keyup.enter.native="props.navigateToTab(props.index)" :transition="props.transition" :index="props.index">
                        <span slot="title" :class="{'text-danger':props.tab.validationError}" v-html="props.tab.title"></span>
                    </wizard-step>
                </template>
                <!-- Headers -->
                <tab-content title="Download">
                    <p>{{$t('common.view.settings.two_factor_auth.activate.download')}}</p>

                    <b-row>
                        <b-col cols="6">
                            <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" target="_blank" class="p-3 b b-primary d-block">
                                <img src="img/2fa/get_android.png" class="mw-100" alt="play store"/>
                            </a>
                        </b-col>
                        <b-col cols="6">
                            <a href="https://itunes.apple.com/app/google-authenticator/id388497605" target="_blank" class="p-3 b b-primary d-block">
                                <img src="img/2fa/get_ios.png" class="mw-100" alt="ios store"/>
                            </a>
                        </b-col>
                    </b-row>
                </tab-content>
                <tab-content title="Install">
                    <p class="mt-3">
                        {{$t('common.view.settings.two_factor_auth.activate.install')}}
                    </p>

                    <div class="text-center">
                      <span v-html="secret.image"></span>
                      <br/>
                      <span>Or enter manually:<br/><b>{{secret.secret}}</b></span>
                    </div>
                </tab-content>
                <tab-content title="Backup">
                    <p>
                        {{$t('common.view.settings.two_factor_auth.activate.backup')}}

                    </p>

                    <span class="b b-primary px-3 py-2 mb-3 d-block text-danger text-center">{{secret.secret}}</span>

                    <b-alert variant="danger" show><i class="fad fa-warning"></i> {{$t('common.view.settings.two_factor_auth.activate.warning')}}</b-alert>
                </tab-content>
                <tab-content title="<div>Enable</div>">
                    <p>
                        {{$t('common.view.settings.two_factor_auth.activate.finish.label')}}
                    </p>

                    <div class="text-center">
                        <b-input v-model="one_time_password" :placeholder="$t('common.view.settings.two_factor_auth.activate.finish.label')" class="d-inline-block mw-100 mt-3 wd-lg"/>
                    </div>

                </tab-content>
                <!-- Directions -->
                <b-btn variant="secondary" slot="prev">{{$t('common.view.settings.two_factor_auth.directions.back')}}</b-btn>
                <b-btn variant="secondary" slot="next">{{$t('common.view.settings.two_factor_auth.directions.next')}}</b-btn>
                <b-btn variant="info" slot="finish" :disabled="!one_time_password" @click="enable()">{{$t('common.view.settings.two_factor_auth.directions.finish')}}</b-btn>
            </form-wizard>
        </div>
    </div>
</template>
<style>
    .vue-form-wizard .wizard-nav-pills > li > a {
        padding: 5px !important;
        width: 150px;
    }
</style>
<script>
    import {FormWizard, TabContent, WizardStep} from 'vue-form-wizard'
    import 'vue-form-wizard/dist/vue-form-wizard.min.css'

    export default {
        components: {
            FormWizard,
            TabContent,
            WizardStep
        },
        data() {
            return {
                loading: false,
                one_time_password: '',
                secret: null
            }
        },
        mounted() {

        },
        methods: {
            is2faEnabled() {
                return this.$store.getters.user('google2fa_enabled_at');
            },
            generateSecret() {
                this.loading = true;
                this.$auth.requestVerfificationCode().then(code => {
                    this.$api.post('user/2fa/generate-secret', {
                        verification_code: code.verification_code
                    }).then(response => {
                        this.loading = false;
                        this.secret = response.data;
                    }).catch((error) => {
                        this.loading = false;
                        if (error.status === 422) {
                            this.$swal.fire('Error', error.data.message, 'error');
                        }
                    });
                });

            },
            enable() {
                this.loading = true;

                this.$api.post('user/2fa/enable', {
                    one_time_password: this.one_time_password,
                }).then(response => {
                    this.loading = false;
                    this.secret = null;
                    this.$swal.fire('Success', 'You 2FA is now enabled!', 'success');
                    this.$store.commit('twoFactorAuthEnabled', response.data.google2fa_enabled_at);
                }).catch((response => {
                    this.$swal.fire('Something is wrong', response.data.message, 'error');
                    this.loading = false;
                }));
            },
            disable() {
                this.loading = true;
                this.$auth.requestVerfificationCode().then(code => {
                    this.$api.post('user/2fa/disable', {
                        verification_code: code.verification_code
                    }).then(() => {
                        this.$swal.fire('Success', 'You 2FA is now disabled, your security level is lowered!', 'warning');
                        this.$store.commit('twoFactorAuthDisabled');
                        this.loading = false;
                    }).catch((error) => {
                        this.loading = false;
                        if (error.status === 422) {
                            this.$swal.fire('Error', error.data.message, 'error');
                        }
                    });
                });
            }
        }
    }
</script>